<template>
    <div>
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              default="today"
              type="year"
              label="대상년도"
              name="issuesYear"
              v-model="searchParam.issuesYear"
            />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :range="true"
              defaultStart="-6M"
              defaultEnd="6M"
              label="작성기간"
              name="period"
              v-model="searchParam.period"
            />
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              codeGroupCd="ISSUES_ITEM_CLASS_CD"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="issuesClassCd"
              label="구분"
              v-model="searchParam.issuesClassCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              stepperGrpCd="IEI_ISSUE_STEP_CD"
              type="search"
              itemText="stepperMstNm"
              itemValue="stepperMstCd"
              name="issuesStepCd"
              label="LBLSTEPPROGRESS"
              v-model="searchParam.issuesStepCd"
            ></c-select>
          </div>
        </template>
      </c-search-box>
      <c-table
        ref="table"
        title="내외부이슈/리스크평가 목록"
        :columns="grid.columns"
        :data="grid.data"
        @linkClick="linkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="LBLREG" icon="add_circle" @btnClicked="addRisk" />
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  
  <script>
  import selectConfig from '@/js/selectConfig';
  export default {
    name: 'internal-external-issues',
    data() {
      return {
        searchParam: {
          plantCd: null,
          period: [],
          issuesYear: '',
          issuesClassCd: null,
          issuesStepCd: null,
        },
        grid: {
          columns: [
            {
              name: 'plantName',
              field: 'plantName',
              label: 'LBLPLANT',
              align: 'center',
              style: 'width:120px',
              sortable: true,
            },
            {
              name: 'issuesYear',
              field: 'issuesYear',
              label: '대상년도',
              style: 'width:100px',
              align: 'center',
              sortable: true,
            },
            {
              name: 'issuesTitle',
              field: 'issuesTitle',
              label: '제목',
              align: 'left',
              style: 'width:250px',
              sortable: true,
              type: 'link',
            },
            {
              name: 'issuesClassName',
              field: 'issuesClassName',
              label: '구분',
              style: 'width:100px',
              align: 'center',
              sortable: true,
            },
            {
              name: 'issuesStepName',
              field: 'issuesStepName',
              label: 'LBLSTEPPROGRESS',
              align: 'center',
              style: 'width:100px',
              sortable: true
            },
            {
              // 결재진행단계
              name: 'approvalStatusName',
              field: 'approvalStatusName',
              label: 'LBL0000212',
              align: 'center',
              sortable: true,
              style: 'width:120px',
            },
            {
              name: 'issuesDeptName',
              field: 'issuesDeptName',
              label: '작성부서',
              align: 'center',
              style: 'width:200px',
              sortable: true
            },
            {
              name: 'issuesDate',
              field: 'issuesDate',
              label: '작성일',
              align: 'center',
              style: 'width:120px',
              sortable: true
            },
          ],
          data: [],
        },
        stepItems: [],
        editable: true,
        listUrl: '',
        popupOptions: {
          isFull: true,
          target: null,
          title: '',
          visible: false,
          top: '',
          param: {},
          closeCallback: null,
        },
      };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    methods: {
      init() {
        // role setting
        this.editable = this.$route.meta.editable;
        // url setting
        this.listUrl = selectConfig.sai.iei.issue.list.url
        // code setting
        this.getList();
      },
      getList() {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      },
      /* eslint-disable no-unused-vars */
      addRisk() {
        this.linkClick();
      },
      linkClick(row, col, index) {
        this.popupOptions.title = '내외부이슈/리스크평가 상세'; 
        this.popupOptions.param = {
          issuesId: row ? row.issuesId : '',
          issuesStepCd: row ? row.issuesStepCd : 'IIS0000001',
        };
        this.popupOptions.target = () => import(`${'./internalExternalIssuesDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      },
      closePopup() {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        this.getList();
      },
    }
  };
  </script>
  